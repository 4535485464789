//
//  Sweetalert2
//

/* CSS Switch */
input.switch {
  display: none;
  + label {
    // font-size: 1em;
    // line-height: 1;
    width: 56px;
    height: 24px;
    background-color: $gray-400;
    background-image: none;
    border-radius: 2rem;
    padding: 0.16667rem;
    padding-bottom: 0px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    position: relative;
    font-weight: $fw-medium;
    transition: all 0.1s ease-in-out;
    &:before {
      color: $dark;
      content: attr(data-off-label);
      display: block;
      font-family: inherit;
      font-weight: 500;
      font-size: 12px;
      line-height: 21px;
      position: absolute;
      right: 1px;
      margin: 3px;

      margin-bottom: 0;
      top: -2px;
      text-align: center;
      min-width: 1.66667rem;
      overflow: hidden;
      transition: all 0.1s ease-in-out;
    }

    &:after {
      content: '';
      position: absolute;
      left: 3px;
      background-color: $gray-200;
      box-shadow: none;
      border-radius: 2rem;
      height: 20px;
      width: 20px;
      top: 2px;
      transition: all 0.1s ease-in-out;
    }
  }

  &:checked + label {
    background-color: $primary;
  }
}

input.switch:checked + label {
  background-color: $primary;
  &:before {
    color: $white;
    content: attr(data-on-label);
    right: auto;
    left: 3px;
  }

  &:after {
    left: 33px;
    background-color: $gray-200;
  }
}

input.switch-bool + label {
  background-color: $danger;
}
input.switch-bool + label:before,input.switch-bool:checked + label:before,
input[switch="default"]:checked + label:before{
  color: $white;
}

input.switch-bool:checked + label {
  background-color: $success;
}

input.switch-default:checked + label {
  background-color: #a2a2a2;
}

input.switch-primary:checked + label {
  background-color: $primary;
}

input.switch-success:checked + label {
  background-color: $success;
}

input.switch-info:checked + label {
  background-color: $info;
}

input.switch-warning:checked + label {
  background-color: $warning;
}

input.switch-danger:checked + label {
  background-color: $danger;
}

input.switch-dark:checked + label {
  background-color: $dark;
  &:before {
    color: $light;
  }
}

.square-switch{
  margin-right: 0px;
  input.switch+label, input.switch+label:after{
    border-radius: 4px;
  }
}



.table-btn:hover{
  cursor: pointer;
  color: black !important;
  
}
.form-switch .form-check-input{
  background-color: lightgray !important;
}
.form-switch .form-check-input:checked{
  background-color: #556EE6 !important;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  .avatar-edit {
      position: absolute;
      right: 40px;
      bottom: 0px;
      z-index: 1;
      input {
          display: none;
          + label {
              display: inline-block;
              width: 34px;
              height: 34px;
              margin-bottom: 0;
              border-radius: 100%;
              background: #FFFFFF;
              border: 1px solid transparent;
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
              cursor: pointer;
              font-weight: normal;
              transition: all .2s ease-in-out;
              &:hover {
                  background: #f1f1f1;
                  border-color: #d6d6d6;
              }
              &:after {
                  content: "\f040";
                  font-family: 'FontAwesome';
                  color: #757575;
                  position: absolute;
                  top: 10px;
                  left: 0;
                  right: 0;
                  text-align: center;
                  margin: auto;
              }
          }
      }
  }
  .avatar-preview {
      width: 150px;
      height: 150px;
      position: relative;
      border-radius: 0;
      border: 6px solid #F8F8F8;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      > div {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
      }
  }
}

.image-areaAA {
  position: relative;
  display: inline-block;
}
.img-circle {
  border-radius: 0;
}
.profile-user-img {
  border: 3px solid #adb5bd;
  margin: 0 auto;
  padding: 3px;
}
.remove-image {
  display: none;
  position: absolute;
  bottom: 4px;
  right: 21%;
  transition: 0.3s;
  opacity: 0;
  padding: 0px 50px;
}
.profile-user-img {
  border: 3px solid #adb5bd;
  margin: 0 auto;
  padding: 3px;
  width: 100px;
}
.product:hover .remove-image{
  opacity: 1;
}

.icon-product{
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product{
  position: relative;
}