//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

.swal-button--confirm:not([disabled]):hover {
  background-color: hsl(229, 51%, 53%);
  color: #fff;
}

.swal-button--confirm {
  border: "2px solid red"; // add border property
}

.swal-button--cancel:not([disabled]):hover {
  background-color: #636678;
  color: #fff;
}

.cancel-button {
  cursor: pointer;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: snow;
}

.cancel-button1 {
  cursor: pointer;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: snow;
  margin-top: "20px";
}

.confirm-button {
  cursor: pointer;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: snow;
}

.swal-title {
  color: #0a0a0ab8;
  font-weight: bold;
  text-transform: none;
  position: relative;
  display: block;
  padding: 13px 16px;
  font-size: 18px !important;
  line-height: normal;
  text-align: center;
  margin-bottom: 0;
}

.swal-text {
  font-size: 14px !important;
  position: relative;
  float: none;
  line-height: normal;
  vertical-align: top;
  text-align: left;
  display: inline-block;
  margin: 0;
  padding: 0 10px;
  font-weight: 500 !important;
  color: #000000b8;
  max-width: calc(100% - 20px);
  overflow-wrap: break-word;
  box-sizing: border-box;
}

// Rounded Buttons

// example 1

.btn-group-example {
  .btn {
    position: relative;

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:before {
      content: "OR";
      position: absolute;
      font-size: 10px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      background-color: $gray-600;
      color: $light;
      border: 1px solid $white;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}

// Example 2

.btn-label {
  position: relative;
  padding-left: 44px;

  .label-icon {
    position: absolute;
    width: 32px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($white, 0.1);
    border-right: 1px solid rgba($white, 0.1);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.btn-light {
    .label-icon {
      background-color: rgba($dark, 0.1);
      border-right: 1px solid rgba($dark, 0.2);
    }
  }
}

.btn-group-vertical {
  label {
    margin-bottom: 0px;
  }
}

//
// Soft Buttons
//

@mixin button-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.1);
  border-color: transparent;
  transition: all 0.5s ease;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $bg;
    border-color: transparent;
  }
}

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    @include button-variant-soft($value);
  }
}

.like-btn {
  &.active {
    .bx-heart {
      color: $danger;

      &:before {
        content: "\ec8f";
      }
    }
  }
}

.delete-button {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: end;
}

.delete-button1 {
  position: absolute;
  top: -6px;
  width: 100%;
  text-align: end;
}

.btn-delete {
  cursor: pointer;
  color: #d53e3e;
}

.btn-edit {
  cursor: pointer;
  color: #1aa572;
}

.btn-edit:hover {
  cursor: pointer;
  color: #1aa572;
}

.btn-close {
  color: #d53e3e;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-custom-white);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #0000006b;
  appearance: none;
  print-color-adjust: exact;
  transition: background-color 0.15s ease-in-out,
    background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.textWrap {
  inline-size: 95px;
  overflow-wrap: break-word;
}

@media (min-width: 768px) {
  .available_button {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .check_button {
    margin-bottom: 20px !important;
  }

  .Button_aline {
    display: flex;
    justify-content: end;
  }
}