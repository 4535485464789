//
//  Sweetalert2
//

.swal2-container {
  .swal2-title {
    font-size: 20px;
    font-weight: $fw-medium;
  }
}

.swal2-modal {
  font-size: 14px;
}

.swal2-icon {
  &.swal2-question {
    border-color: $info;
    color: $info;
  }
  &.swal2-success {
    [class^="swal2-success-line"] {
      background-color: $success;
    }

    .swal2-success-ring {
      border-color: rgba($success, 0.3);
    }
  }
  &.swal2-warning {
    border-color: $warning;
    color: $warning;
  }
}

.swal2-styled {
  &:focus {
    box-shadow: none;
  }
}

.swal2-close:focus {
  outline: none !important;
  box-shadow: none !important;
}

.swal2-close:hover {
  transform: none;
  background: rgba(0, 0, 0, 0);
  color: #525050;
}

.swal2-close {
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: -1.2em;
  padding: 0;
  overflow: hidden;
  transition: color 0.1s, box-shadow 0.1s;
  border: none;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0);
  color: #7e7b7b;
  font-family: serif;
  font-family: monospace;
  font-size: 2.5em;
  cursor: pointer;
  justify-self: end;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  display: block;
  position: absolute;
  top: 1.9em;
  width: 1.9375em;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #f27474;
}

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 4em;
  height: 3.5rem;
  margin: 2em auto 0.6em;
  border: 0.25em solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
}

.swal2-progress-steps {
  .swal2-progress-step {
    background: $primary;
    &.swal2-active-progress-step {
      background: $primary;
      & ~ .swal2-progress-step,
      & ~ .swal2-progress-step-line {
        background: rgba($primary, 0.3);
      }
    }
  }

  .swal2-progress-step-line {
    background: $primary;
  }
}

.swal2-loader {
  border-color: $primary transparent $primary transparent;
}
.swal2-popup {
  background: var(--#{$prefix}custom-white);
}

.swal2-title,
.swal2-html-container {
  color: var(--#{$prefix}gray-700);
}

.swal2-file,
.swal2-input,
.swal2-textarea {
  border-color: var(--#{$prefix}border-color);
  color: var(--#{$prefix}body-color);
  &:focus {
    box-shadow: none;
    border-color: var(--#{$prefix}border-color);
  }
}
