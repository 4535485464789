//
// _layouts.scss
//

body[data-layout-size="boxed"] {
  background-color: $boxed-body-bg;

  #layout-wrapper {
    background-color: var(--#{$prefix}body-bg);
    max-width: $boxed-layout-width;
    margin: 0 auto;
    box-shadow: $box-shadow;
  }

  #page-topbar {
    max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
    margin: 0 auto;
  }

  &.vertical-collpsed {
    #page-topbar {
      max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
      margin: 0 auto;
    }

    .main-content {
      min-height: $layout-collapsed-min-height;
    }
  }

  &[data-sidebar-size="small"] {
    #page-topbar {
      max-width: calc(#{$boxed-layout-width} - #{$sidebar-width-sm});

      // left: $sidebar-width-sm;
      @media (max-width: 991.98px) {
        left: 0;
      }
    }
  }

  .footer {
    margin: 0 auto;
    max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
  }

  &.vertical-collpsed {
    .footer {
      max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
    }
  }
}

// Horizontal Boxed Layout

body[data-layout="horizontal"][data-layout-size="boxed"] {

  #page-topbar,
  #layout-wrapper,
  .footer {
    max-width: 100%;
  }

  .container-fluid,
  .navbar-header {
    max-width: $boxed-layout-width;
  }

  &.vertical-collpsed {
    .main-content {
      min-height: auto;
    }
  }
}

// Scrollable layout

body[data-layout-scrollable="true"] {
  @media (min-width: 992px) {

    #page-topbar,
    .vertical-menu,
    .navbar-brand-box {
      position: absolute;
    }
  }

  &[data-layout="horizontal"] {
    @media (min-width: 992px) {

      #page-topbar,
      .topnav {
        position: absolute;
      }

      .navbar-brand-box {
        position: relative;
      }
    }
  }
}

.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #183153;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {

  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

.loading-overlay {
  display: none;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
}

.loading-overlay.is-active {
  display: flex;
}

.booked-status {
  background-color: #b5ebb7 !important;
}

.deliverd-status {
  background-color: #b5ebb7 !important;
}

.subAdmin-true {
  background-color: #ff000014 !important;
}

.return-status {
  background-color: #b5ebb7 !important;
}

.booking-status-booking {
  background-color: #B6E2A1 !important;
  color: black;
}

.deliverd-status-booking {
  background-color: #FFD89C !important;
  color: black;
}

.return-status-booking {
  background-color: #9AC5F4 !important;
  color: black;
}

.hold-status-booking {
  background-color: #FFBDF7 !important;
  color: black;
}

.hold-status {
  background-color: #FFBDF7 !important;
}

.cancle-status-booking {
  background-color: #caccd7a3 !important;
  color: black;
}

.Subscription_expired {
  background-color: #f46a6a40 !important;
  color: black;
}
.Subscription_warning {
  background-color: #fdefbe !important;
  color: black;
}


.border-Right {
  border-right: 1px solid #959595b0 !important;
}

.table {
  margin-bottom: 0 !important;
}

.serchButton {
  margin-top: 0px !important;
}

.serchButtonMargin {
  margin-top: 0px !important;
}

.css-13cymwt-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  /* border-radius: 4px; */
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}

.css-eb0iqw-control:hover {
  border-color: hsl(0, 0%, 80%) !important;
}

.retireProductStyle {
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}

.paddingStyle {
  border: 1px solid #FFD89C;
  padding: 2px 8px;
}

.aline-right {
  text-align: end;
}

.aline-left {
  text-align: left;
}

.column-dispaly {
  display: none;
}

.column-display {
  display: none;
}

.header-display {
  text-align: left !important;
}

.control-select:hover {
  border-color: 1px solid #b3b3b3 !important;
}

.control-select:hover {
  border-color: 1px solid #b3b3b3 !important;
}

.btn-pdf {
  --bs-btn-color: #fff;
  --bs-btn-bg: #F24C3D;
  --bs-btn-border-color: #F24C3D;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dd3d2e;
  --bs-btn-hover-border-color: #dd3d2e;
  --bs-btn-focus-shadow-rgb: 246, 128, 128;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #F24C3D;
  --bs-btn-active-border-color: #F24C3D;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #F24C3D;
  --bs-btn-disabled-border-color: #F24C3D;
}

.btn-excel {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5D9C59;
  --bs-btn-border-color: #5D9C59;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #508f4b;
  --bs-btn-hover-border-color: #508f4b;
  --bs-btn-focus-shadow-rgb: 246, 128, 128;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5D9C59;
  --bs-btn-active-border-color: #5D9C59;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5D9C59;
  --bs-btn-disabled-border-color: #5D9C59;
}


.btn-label1 .label-icon1 {
  position: absolute;
  width: 32px;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #ffffff00;
  border-right: 1px solid #ffffff1a;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.btn-label1 {
  position: relative;
  padding-left: 44px;

  .label-icon1 {
    position: absolute;
    width: 32px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #ffffff00;
    border-right: 1px solid #ffffff1a;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.btn-light {
    .label-icon {
      background-color: rgba($dark, 0.1);
      border-right: 1px solid rgba($dark, 0.2);
    }
  }
}



.slider-index {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1;
}



/* Add this CSS to your component or a separate CSS file */

/* The full-screen modal overlay with transparent background */
.full-screen-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* The full-screen modal content */
.full-screen-modal-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
}

.fullscreen {
  /* Styles for fullscreen mode */
  width: 100%;
  height: 100%;
}

.fullscreen-image {
  /* Styles for the image in fullscreen mode */
  cursor: pointer;
  // transition: transform 0.3s ease-in-out;
  object-fit: contain;
  // max-width: 100%;
  // max-height: 100%;
}

/* The close button in the top-right corner */
.close-button {
  position: absolute;
  top: -55px;
  right: -35px;
  background: transparent;
  border: none;
  font-size: 20px;
  color: white;
  cursor: pointer;
  z-index: 1;
}

.slider-container {
  width: 500px;
}

.slide-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
}

.slider-image {
  max-width: 100%;
  max-height: 100%;
}

.critical-row {
  background-color: #fdefbe !important;
}

.product-img {
  position: relative;
}

.play-button-overlay {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: white;
  opacity: 1;
}

.play-button-overlay1 {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: white;
  opacity: 1;
}

.play-button-overlay2 {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: white;
  opacity: 1;
}


.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  /* max-width: 616px; */
  height: 500px;
  width: 480px;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  -ms-touch-action: none;
  touch-action: none;
}

.gst-amounts {
  padding: 5px;
  font-weight: 400 !important;
}

.gst-amounts2 {
  padding: 5px;
  font-weight: 500 !important;
  color: #0F6E25;
}

.gst-amounts3 {
  padding: 5px;
  font-weight: 500 !important;
  color: #556ee6;
}


.selected-row {
  background-color: #5760682e !important;
}

.visibility {
  visibility: hidden !important;
}

.groomwellImg {
  filter: brightness(0) saturate(100%) invert(45%) sepia(22%) saturate(364%) hue-rotate(188deg) brightness(93%) contrast(88%);
}

.groomwellImg:hover {
  filter: sepia(100%) brightness(200%) hue-rotate(360deg) saturate(132%) contrast(1000%) invert(8%);
  -webkit-filter: sepia(100%) brightness(200%) hue-rotate(360deg) saturate(132%) contrast(1000%) invert(8%);
  -moz-filter: sepia(100%) brightness(200%) hue-rotate(360deg) saturate(132%) contrast(1000%) invert(8%);
}

.ColorCode_box {
  text-align: center !important;
  height: 13px;
  width: 13px;
}

.custom-barcode svg text {
  // transform: translate(22px, -11px);
  font-size: 28px !important;
  /* Adjust x and y values here */
}

// .custom-barcode svg {
//   overflow: visible;
//   /* Ensure overflow is visible if the text moves out of bounds */
// }