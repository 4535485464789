//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-bordered {
  border: $table-border-width solid $table-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table > :not(:first-child) {
  border-top: 0;
}

@media (max-width: 768px) {
  .table-header {
    margin: 8px;
  }
}

.table-cell {
  padding: 4px !important;
  height: 10px;
}

.table-body {
  padding: 5px !important;
}

.table-display {
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
}
